<template>
  <div class="initiate-collaboration">
    <baidu-map
      :initMap="initMap"
      :scrollWheelZoom="true"
      :showCall="true"
      @mapCall="handleMapCall"
      :polylinesArr="polylinesArr"
      :center="center"
    ></baidu-map>
    <div class="js-call-list">
      <div>
        <el-button
          v-for="item in callOption"
          :key="item.value"
          class="js-call-item"
          :class="listType == item.value ? 'active' : ''"
          @click="contactClick(item)"
          size="small"
          plain
        >
          <div
            :class="[item.value === 1 ? 'call-icon' : 'call-icon-message']"
          ></div>
          <div class="call-name">
            {{ item.label }}
            <span v-if="item.value">({{ userNum }})</span>
            <span v-else>({{ sessionNum }})</span>
          </div>
        </el-button>
        <!--
        <el-button class="js-call-caret" @click="toggleCaret" size="small">
          <i :class="isCaret?'el-icon-caret-bottom':'el-icon-caret-top'"></i>
        </el-button>
        -->
      </div>
      <ul
        class="js-call-list-content"
        :class="userList.length ? '' : 'noData'"
        v-show="isShowContent"
      >
        <template v-if="userList.length">
          <template v-if="listType === 1">
            <li
              class="js-call-list-item"
              v-for="item in userList"
              :class="item.onlineStatus ? '' : 'off-line'"
              :key="item.id"
            >
              <img
                v-if="item.avatar"
                class="client-icon"
                :src="item.avatar"
                alt="user-avatar"
              />
              <img
                v-if="!item.avatar"
                class="client-icon"
                src="@/assets/images/userImg.png"
                alt="default-avatar"
              />
              <div class="client-info">
                <p class="client-name">
                  {{ item.realName }}<span v-if="item.oneself">（我）</span>
                </p>
                <i
                  class="client-type"
                  :class="item.devType === 'web' ? 'client-type-web' : ''"
                ></i>
              </div>
              <el-button
                v-if="
                  item.onlineStatus && !item.oneself && item.devType !== 'web'
                "
                class="client-call"
                type="primary"
                size="mini"
                plain
                @click="handleCall(item)"
                >呼叫</el-button
              >
            </li>
          </template>
          <template v-else>
            <li
              class="js-call-list-item"
              v-for="item in sessionList"
              :key="item.id"
            >
              <img class="client-icon" src="@/assets/images/33.png" alt="" />
              <div class="client-info">
                <p class="client-name">{{ get10Str(item.channel) }}</p>
                <p class="client-time">{{ item.gmtCreated }}</p>
              </div>
              <el-button
                class="client-call"
                type="primary"
                size="mini"
                plain
                @click="joinSession(item)"
                >加入</el-button
              >
            </li>
          </template>
        </template>
        <template v-else>
          <li>
            <el-empty description="暂无数据"></el-empty>
          </li>
        </template>
      </ul>
    </div>
  </div>
</template>

<script>
import {
  installer as callInstaller,
  RCCallErrorCode,
} from "@rongcloud/plugin-call";
import BaiduMap from "@/components/BaiduMap";
import CallDialog from "@/components/Call";
import { mapGetters } from "vuex";
export default {
  name: "InitiateCollaboration",
  components: {
    BaiduMap,
    CallDialog,
  },
  data() {
    return {
      callOption: [
        {
          label: "通讯列表",
          value: 1,
        },
        {
          label: "当前会话",
          value: 0,
        },
      ],
      userNum: 0,
      sessionNum: 0,
      listType: 1,
      isCaret: true,
      isShowContent: false,
      userList: [],
      sessionList: [],
      callDialogVisible: false,
      polylinesArr: [],
      center: { lng: 116.404, lat: 39.915 },
      rtcOptions: {
        channel: "",
        token: "",
      },
      timer: null,
      CommonRongIMLib: null, // 融云IM实例
      CommonRtcClient: "", // 公共的RTM client
      CommonRongCaller: null, //公共的融云1v1呼叫实例
      rtmCommonChannel: "", // 公共的RTM channel
      _remoteInvitation: null,
      initMap: false,
    };
  },
  computed: {
    ...mapGetters(["getUserInfo", "getAllUsersFromRcSent"]),
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  mounted() {
    this.getPolylines();
    this.getContentList();
    this.init();
  },
  watch: {
    // 通讯列表更新
    getAllUsersFromRcSent: {
      handler(newV, originV) {
        this.userList.map((item, index) => {
          if (typeof this.getAllUsersFromRcSent[item.id] === "number") {
            this.userList[index].onlineStatus =
              this.getAllUsersFromRcSent[item.id];
          }
        });
      },
    },
  },
  methods: {
    init() {
      // rtmCommonChannel为异步第二步，它初始化完成后，2个对象就都有了，再执行事件绑定
      if (window?.GLOBAL?.CommonRtcClient) {
        this.CommonRtcClient = window.GLOBAL.CommonRtcClient;
        this.CommonRongIMLib = window.GLOBAL.CommonRongIMLib;
        this.CommonRongCaller = window.GLOBAL.Caller;
        this.Events = window.GLOBAL.Events;
        
      } else {
        setTimeout(this.init, 2000);
      }
    },
    getContentList() {
      this.getCallList();
      this.getSession();
    },
    // 联系人菜单
    contactClick(item) {
      this.listType = item.value;
      if (!this.isShowContent) {
        this.isShowContent = true;
      }
      if (this.listType === 1) {
        this.getCallList();
      } else {
        this.getSession();
      }
    },
    // 获取通讯列表
    getCallList() {
      this.$axios({
        method: "get",
        url: "api/csp/mgr/v1/dept/users/0",
      }).then((response) => {
        let data = response.data;
        if (!data.status) {
          this.userNum = data.data.length;
          this.userList = data.data;
          this.setUseStatus(this.userList);
        } else {
          this.$message({
            type: "error",
            message: data.msg,
            duration: 1000,
          });
        }
      });
    },
    // 获取当前会话
    getSession() {
      this.$axios({
        method: "get",
        url: "api/csp/rc/v1/session/list",
      }).then((response) => {
        let data = response.data;
        if (!data.status) {
          this.sessionNum = data.data.length;
          this.sessionList = data.data;
        } else {
          this.$message({
            type: "error",
            message: data.msg,
            duration: 1000,
          });
        }
      });
    },
    // 获取轨迹列表
    getPolylines() {
      this.$axios({
        method: "get",
        url: `api/csp/mgr/v1/userGps/record`,
      }).then((response) => {
        let data = response.data;
        if (!data.status) {
          let list = data.data.filter((item) => {
            return item.gpsList.length > 0;
          });
          if (list.length) {
            this.polylinesArr = list;
          }
          this.initMap = true;
        } else {
          this.$message({
            type: "error",
            message: data.msg,
            duration: 1000,
          });
        }
      });
    },
    // 监听用户状态
    setUseStatus(list) {
      if (!this.CommonRtcClient) {
        setTimeout(() => {
          this.setUseStatus(list);
        }, 500);
        return;
      }
      // 监听列表里用户的状态
      // this.CommonRtcClient.subscribePeersOnlineStatus(
      //   list.map((item) => item.id.toString())
      // );
      // 状态改变回调
      // this.CommonRtcClient.addEventListener("CHATROOM", (event) => {
      //   if (event.rejoinedRoom) {
      //     console.log("SDK 内部重连聊天室信息:", event.rejoinedRoom);
      //   }
      //   if (event.updatedEntries) {
      //     console.log("监听到的聊天室 KV 更新:", event.updatedEntries);
      //   }
      //   if (event.userChange) {
      //     console.log("加入退出的用户通知:", event.userChange);
      //   }
      //   if (event.chatroomDestroyed) {
      //     console.log("聊天室销毁:", event.chatroomDestroyed);
      //   }

      //   return;
      //   this.userList.forEach((item, index) => {
      //     if (!res[item.id]) return;

      //     const status = res[item.id];
      //     item.onlineStatus = status === "ONLINE";
      //     this.$set(this.userList, index, item);
      //   });
      // });
    },
    toggleCaret() {
      this.isCaret = !this.isCaret;
      this.isShowContent = !this.isShowContent;
    },
    // 创建会话
    getChannel() {
      return new Promise((resolve, reject) => {
        this.$axios({
          method: "get",
          url: `api/csp/rc/v1/session/channel`,
        })
          .then(({ data }) => {
            const res = data.data;
            if (!data.status && res && res.channel) {
              resolve(res.channel);
            }
          })
          .catch(() => {
            reject();
          });
      });
    },
    // 呼叫
    async handleCall(item) {
      // 融云的目标id必须是字符串，不然报错
      const targetId = item.id.toString();
      // 显示呼叫弹窗
      this.$store.commit("setCallDialogData", {
        show: true,
        name: item.realName,
        isCalled: false,
        avatar: "",
        callerId: targetId, //被叫id从通讯列表取
      });
      // 服务器生成一个新的channel
      this.rtcOptions.channel = await this.getChannel(item.id);
      // 主叫-存储当前频道
      this.$store.commit("setRongCloudData", {
        channelId: this.rtcOptions.channel,
      });
      // 发送呼叫消息
      const { realName, fullAvatar, id } = this.getUserInfo;
      const message = {
        rtc_call: {
          channel: this.rtcOptions.channel,
          token: "",
          platform: "web",
          name: realName,
          avatar: fullAvatar,
          userId: id,
        },
      };
      this.sendMessage(message, targetId);
    },
    sendMessage(msg, targetId) {
      // 指定消息发送的目标会话
      const conversation = {
        // targetId
        targetId,
        // 会话类型：RongIMLib.ConversationType.PRIVATE | RongIMLib.ConversationType.GROUP
        conversationType: this.CommonRongIMLib.ConversationType.PRIVATE,
      };

      // 构建文本消息
      const message = new this.CommonRongIMLib.TextMessage({
        content: JSON.stringify(msg),
      });

      // 发送消息
      this.CommonRongIMLib.sendMessage(conversation, message).then(
        ({ code, data }) => {
          if (code === 0) {
            console.log("消息发送成功：", data);
          } else {
            console.log("消息发送失败：", code);
          }
        }
      );
    },
    // 加入会话
    async joinSession(item) {
      console.log("会话：", item);
      const { channel } = item;
      // 判断通话状态
      const status = await this.$axios({
        method: "get",
        url: `api/csp/rc/v1/session/status/${channel}`,
      });

      const userStatus = status.data.data;
      if (userStatus === 2) {
        // 已结束
        this.$message({
          type: "error",
          message: "会话已结束",
          duration: 3000,
        });
        return;
      }
      // 加入频道
      this.rtcOptions.channel = item.channel;
      this.$store.commit("setRongCloudData", {
        channelId: this.rtcOptions.channel,
      });
      this.gotoCurrentCollaboration();
    },
    gotoCurrentCollaboration() {
      this.$router.push({
        path: "/remoteCollaboration/currentCollaboration",
        query: {
          origin: "call",
        },
      });
      // setTimeout(() => {
      //   window.location.reload();
      // }, 1000);
    },
    // 监听远端呼叫
    watchRemoteInvitation() {
      this.CommonRtcClient.on(
        "RemoteInvitationReceived",
        (remoteInvitation) => {
          console.log("Receive call invitation", remoteInvitation.callerId);
          console.log("remoteInvitation", remoteInvitation);
          console.log("-----------------------被呼叫---------------");
          console.log(this._remoteInvitation);
          if (this._remoteInvitation !== null) {
            this._remoteInvitation = null;
          }
          this._remoteInvitation = remoteInvitation;
          this.callDialogVisible = true;
          this.isJoin = true;
          this.peerEvents();
        }
      );
    },
    // 监听主叫状态变化
    peerEvents() {
      this._remoteInvitation.on("RemoteInvitationAccepted", () => {
        console.log("主叫收到接受消息");
        this.callDialogVisible = false;
        // 获取channel，跳转当前协作页面
        console.log(this._remoteInvitation._content);
        this.rtcOptions.channel = this._remoteInvitation._content;
        this.gotoCurrentCollaboration();
      });

      this._remoteInvitation.on("RemoteInvitationCanceled", () => {
        console.log("主叫取消");
        this.callDialogVisible = false;
      });

      this._remoteInvitation.on("RemoteInvitationFailure", () => {
        console.log("主叫呼叫失败");
        this.callDialogVisible = false;
      });
    },
    // 地图里面的呼叫
    handleMapCall(user) {
      this.callDialogVisible = true;
      // 发送呼叫
      this.handleCall(user);
    },
    get10Str(str) {
      if (!str) return;
      return str.substring(0, 10);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/initiateCollaboration.scss";
</style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "initiate-collaboration" },
    [
      _c("baidu-map", {
        attrs: {
          initMap: _vm.initMap,
          scrollWheelZoom: true,
          showCall: true,
          polylinesArr: _vm.polylinesArr,
          center: _vm.center,
        },
        on: { mapCall: _vm.handleMapCall },
      }),
      _c("div", { staticClass: "js-call-list" }, [
        _c(
          "div",
          _vm._l(_vm.callOption, function (item) {
            return _c(
              "el-button",
              {
                key: item.value,
                staticClass: "js-call-item",
                class: _vm.listType == item.value ? "active" : "",
                attrs: { size: "small", plain: "" },
                on: {
                  click: function ($event) {
                    return _vm.contactClick(item)
                  },
                },
              },
              [
                _c("div", {
                  class: [item.value === 1 ? "call-icon" : "call-icon-message"],
                }),
                _c("div", { staticClass: "call-name" }, [
                  _vm._v(" " + _vm._s(item.label) + " "),
                  item.value
                    ? _c("span", [_vm._v("(" + _vm._s(_vm.userNum) + ")")])
                    : _c("span", [_vm._v("(" + _vm._s(_vm.sessionNum) + ")")]),
                ]),
              ]
            )
          }),
          1
        ),
        _c(
          "ul",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isShowContent,
                expression: "isShowContent",
              },
            ],
            staticClass: "js-call-list-content",
            class: _vm.userList.length ? "" : "noData",
          },
          [
            _vm.userList.length
              ? [
                  _vm.listType === 1
                    ? _vm._l(_vm.userList, function (item) {
                        return _c(
                          "li",
                          {
                            key: item.id,
                            staticClass: "js-call-list-item",
                            class: item.onlineStatus ? "" : "off-line",
                          },
                          [
                            item.avatar
                              ? _c("img", {
                                  staticClass: "client-icon",
                                  attrs: {
                                    src: item.avatar,
                                    alt: "user-avatar",
                                  },
                                })
                              : _vm._e(),
                            !item.avatar
                              ? _c("img", {
                                  staticClass: "client-icon",
                                  attrs: {
                                    src: require("@/assets/images/userImg.png"),
                                    alt: "default-avatar",
                                  },
                                })
                              : _vm._e(),
                            _c("div", { staticClass: "client-info" }, [
                              _c("p", { staticClass: "client-name" }, [
                                _vm._v(" " + _vm._s(item.realName)),
                                item.oneself
                                  ? _c("span", [_vm._v("（我）")])
                                  : _vm._e(),
                              ]),
                              _c("i", {
                                staticClass: "client-type",
                                class:
                                  item.devType === "web"
                                    ? "client-type-web"
                                    : "",
                              }),
                            ]),
                            item.onlineStatus &&
                            !item.oneself &&
                            item.devType !== "web"
                              ? _c(
                                  "el-button",
                                  {
                                    staticClass: "client-call",
                                    attrs: {
                                      type: "primary",
                                      size: "mini",
                                      plain: "",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleCall(item)
                                      },
                                    },
                                  },
                                  [_vm._v("呼叫")]
                                )
                              : _vm._e(),
                          ],
                          1
                        )
                      })
                    : _vm._l(_vm.sessionList, function (item) {
                        return _c(
                          "li",
                          { key: item.id, staticClass: "js-call-list-item" },
                          [
                            _c("img", {
                              staticClass: "client-icon",
                              attrs: {
                                src: require("@/assets/images/33.png"),
                                alt: "",
                              },
                            }),
                            _c("div", { staticClass: "client-info" }, [
                              _c("p", { staticClass: "client-name" }, [
                                _vm._v(_vm._s(_vm.get10Str(item.channel))),
                              ]),
                              _c("p", { staticClass: "client-time" }, [
                                _vm._v(_vm._s(item.gmtCreated)),
                              ]),
                            ]),
                            _c(
                              "el-button",
                              {
                                staticClass: "client-call",
                                attrs: {
                                  type: "primary",
                                  size: "mini",
                                  plain: "",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.joinSession(item)
                                  },
                                },
                              },
                              [_vm._v("加入")]
                            ),
                          ],
                          1
                        )
                      }),
                ]
              : [
                  _c(
                    "li",
                    [_c("el-empty", { attrs: { description: "暂无数据" } })],
                    1
                  ),
                ],
          ],
          2
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="js-login js-login-building">
    <div class="js-login-download-app">
      <ul class="js-login-list">
        <li>
          <img
            class="js-login-download-img"
            src="@/assets/images/d_logo@2x.png"
            alt=""
          />
        </li>
        <li>
          <el-button
            class="js-login-download-btn"
            type="primary"
            size="medium"
            @click="handleDownloadApp"
            >下载应用</el-button
          >
        </li>
      </ul>
    </div>
    <div class="js-login-content">
      <!-- <img class="js-login-logo" src="@/assets/images/logo.png" alt=""> -->
      <div class="js-login-ll"></div>
      <div class="js-login-login-text">登录</div>
      <ul class="js-login-list">
        <!-- <li class="js-login-label">账号</li> -->
        <li>
          <el-input
            v-model="username"
            placeholder="账号"
            size="large"
            class="js-login-li"
          >
            <!--<i slot="prefix" class="el-input__icon el-icon-user"></i>-->
            <i slot="prefix" class="el-input__icon js-login-icon-user"></i>
          </el-input>
        </li>
        <!-- <li class="js-login-label">租户编码</li> -->
        <li>
          <el-input
            v-model="tenantCode"
            placeholder="租户编码"
            size="large"
            class="js-login-li"
          >
            <!--<i slot="prefix" class="el-input__icon el-icon-set-up"></i>-->
            <i
              slot="prefix"
              class="el-input__icon js-login-icon-tenant-code"
            ></i>
          </el-input>
        </li>
        <!-- <li class="js-login-label">密码</li> -->
        <li>
          <el-input
            v-model="password"
            placeholder="密码"
            show-password
            size="large"
            class="js-login-li"
          >
            <!--<i slot="prefix" class="el-input__icon el-icon-lock"></i>-->
            <i slot="prefix" class="el-input__icon js-login-icon-lock"></i>
          </el-input>
        </li>
        <li>
          <el-checkbox
            class="js-login-tip"
            v-model="checked"
            @change="handleChecked"
            >记住用户名密码</el-checkbox
          >
        </li>
        <li>
          <el-button
            class="js-login-btn"
            type="primary"
            @click="handleSubmit"
            size="medium"
            >登录</el-button
          >
        </li>
      </ul>
    </div>
    <div class="js-login-dd"></div>
    <Verify
      ref="verify"
      @success="handleVerify"
      captchaType="blockPuzzle"
    ></Verify>
    <div class="record-code">
      Copyright © 2021 嘉仕企业服务平台 All Rights Reserved
      <a href="https://beian.miit.gov.cn/"> 京ICP备2022024506号-1</a>
    </div>
  </div>
</template>

<script>
import Verify from "@/components/verifition/Verify";

export default {
  name: "",
  components: {
    Verify,
  },
  data() {
    return {
      username: "",
      password: "",
      tenantCode: "",
      checked: false,
    };
  },
  mounted() {
    let username = this.getCookie("jaresh_username");
    let tenantCode = this.getCookie("jaresh_tenantCode");
    let password = this.getCookie("jaresh_password");

    if (username && password) {
      this.username = username;
      this.tenantCode = tenantCode;
      this.password = password;
      this.checked = true;
    }
    var a ={b:2,c:3}
    console.log(1??0)
    console.log(a?.d)
    console.log(a?.c)
  },
  methods: {
    handleDownloadApp() {
      var browser = {
        versions: (function () {
          var u = navigator.userAgent,
            app = navigator.appVersion;
          return {
            trident: u.indexOf("Trident") > -1, //IE内核
            presto: u.indexOf("Presto") > -1, //opera内核
            webKit: u.indexOf("AppleWebKit") > -1, //苹果、谷歌内核
            gecko: u.indexOf("Gecko") > -1 && u.indexOf("KHTML") == -1, //火狐内核
            mobile:
              !!u.match(/AppleWebKit.*Mobile.*/) || !!u.match(/AppleWebKit/), //是否为移动终端
            ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //ios终端
            android: u.indexOf("Android") > -1 || u.indexOf("Linux") > -1, //android终端或者uc浏览器
            iPhone: u.indexOf("iPhone") > -1 || u.indexOf("Mac") > -1, //是否为iPhone或者QQHD浏览器
            iPad: u.indexOf("iPad") > -1, //是否iPad
            webApp: u.indexOf("Safari") == -1, //是否web应该程序，没有头部与底部
          };
        })(),
        language: (
          navigator.browserLanguage || navigator.language
        ).toLowerCase(),
      };

      // 之后有区分的时候再改，现在默认都一样下载android
      if (
        browser.versions.ios ||
        browser.versions.iPhone ||
        browser.versions.iPad
      ) {
        //判断是IOS后跳转到相应的链接
        window.location.href =
          "https://apk-1305418344.cos.ap-beijing.myqcloud.com/jiashi_android_release.apk";
      } else if (browser.versions.android) {
        //判断是android后跳转到相应的链接
        window.open(
          "https://apk-1305418344.cos.ap-beijing.myqcloud.com/jiashi_android_release.apk"
        );
      } else {
        window.open(
          "https://apk-1305418344.cos.ap-beijing.myqcloud.com/jiashi_android_release.apk"
        );
      }
    },
    handleChecked() {
      if (!this.checked) {
        this.delCookie("jaresh_username");
        this.delCookie("jaresh_tenantCode");
        this.delCookie("jaresh_password");
      }
    },
    handleSubmit() {
      this.$refs.verify.show();
    },
    handleVerify() {
      this.handleLogin();
    },
    // 用户登录
    handleLogin() {
      this.$axios({
        method: "post",
        url: "api/csp/v1/tenant/user/login",
        data: {
          name: this.username,
          password: this.password,
          tenantCode: this.tenantCode,
          devType: "web",
        },
      }).then((response) => {
        let res = response.data;
        if (!res.status) {
          let data = res.data;
          localStorage.setItem("eleToken", data.token);
          localStorage.setItem("userId", data.id);
          localStorage.setItem("appId", data.appId);
          // 记录下一个信息，到remoteCollaboration页面发送消息
          sessionStorage.setItem("SEND_LOGIN_MSG_ONCE", 1);

          if (this.checked) {
            this.setCookie("jaresh_username", this.username, 7);
            this.setCookie("jaresh_tenantCode", this.tenantCode, 7);
            this.setCookie("jaresh_password", this.password, 7);
          }

          this.$store.commit("setUserInfo", data);
          this.$router.push({ path: "/remoteCollaboration" });
          setTimeout(() => {
            window.location.reload()
          }, 0);
        } else {
          this.$message({
            type: "error",
            message: res.msg,
            duration: 1000,
          });
        }
      });
    },
    setCookie(name, value, day) {
      let date = new Date();
      date.setDate(date.getDate() + day);
      document.cookie = name + "=" + value + ";expires=" + date;
    },
    getCookie(name) {
      var reg = RegExp(name + "=([^;]+)");
      var arr = document.cookie.match(reg);
      if (arr) {
        return arr[1];
      } else {
        return "";
      }
    },
    delCookie(name) {
      this.setCookie(name, null, -1);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/login.scss";
.record-code {
  position: absolute;
  width: 100%;
  bottom: 0;
  height: 30px;
  line-height: 30px;
  text-align: center;
  background: #fff;
  font-size: 13px;
  color: rgb(102, 102, 102);

  a {
    color: rgb(102, 102, 102);
  }
}
@media (max-width: 1440px) {
  .js-login-content {
    width: 400px;
    height: 500px;
    .js-login-ll {
      height: 60px;
    }
  }
  .js-login-download-app{
    margin-top: 272px;
  }
}
</style>

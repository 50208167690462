import request from "@/assets/js/axios.js" //调用项目封装的axios

// 获取验证图片  以及token
export function reqGet(data) {
  return request({
    method: 'post',
    url: 'api/csp/mgr/captcha/get',
    data
  })
}

// 滑动或者点选验证
export function reqCheck(data) {
  return request({
    method: 'post',
    url: 'api/csp/mgr/captcha/check',
    data
  })
}

